import React, {useState, useEffect} from "react"
import {getRecipe} from "../controllers/RecipeController"
import { addRecipeToFavorites, removeRecipeFromFavorites, getUser } from "../controllers/UserController";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Paper from "@mui/material/Paper"
import PageHeader from "./uiComponents/PageHeader"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import Container from '@mui/material/Container';
import AuthPagesWrapper from "./AuthPagesWrapper";
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const ViewRecipe = (props) => {

    document.title = props.title

    let source = ""
    
    const [recipeData, setRecipeData] = useState(false)
    const [alreadyFavorite, setAlreadyFavorite] = useState(false)
    const [tagsString, setTagsString] = useState()
    const [loadReady, setLoadReady] = useState(false)
    const [currentUserRecipe, setCurrentUserRecipe] = useState(false)

    useEffect(() => {
        document.title = props.title
        if(props.userDbId) {
        getUser(props.userDbId)
        .then(userData => {
            if(userData.favoriteRecipes) {
                if(userData.favoriteRecipes.indexOf(props.recipeId) > -1){
                    setAlreadyFavorite(true)
                }
            }
        })
    }
    getRecipe(props.recipeId)
    .then(apiRes => {
        if(apiRes.tags.length > 0) {
        let tagString = ""
        for(let i=0; i < apiRes.tags.length; i++) {
            if(i !== apiRes.tags.length - 1) {
                tagString += `${apiRes.tags[i]}, `
            } else {
                tagString += `${apiRes.tags[i]}`
            }
        }
        setTagsString(tagString)
    }
        setRecipeData(apiRes)
        if(apiRes.userDbId === props.userDbId) {
            setCurrentUserRecipe(true)
        }
        setLoadReady(true)
    })
    .catch(err => console.log(err))
    }, [props.userFavoriteRecipes, props.title])

    const handleSetFavorite = () => {
        if(alreadyFavorite) {
            setAlreadyFavorite(false)
            removeRecipeFromFavorites(props.userDbId, props.recipeId)
        } else {
            setAlreadyFavorite(true)
            addRecipeToFavorites(props.userDbId, props.recipeId)
        }
    }

    if(recipeData.source) {
        source = recipeData.source
    } else {
        source = "Original recipe"
    }

        return (
            <>
                {loadReady &&
            <Container sx={{paddingTop: "20px", paddingBottom: "20px"}}>
                <PageHeader sendTo={"/"} pageTitle="Recipe Details"/> 
                {props.auth &&
                <>
                    {alreadyFavorite ?
                        <StarIcon onClick={handleSetFavorite} color="primary" sx={{float: "right", fontSize: "30px"}}/>
                    :
                        <StarOutlineIcon onClick={handleSetFavorite} color="primary" sx={{float: "right", fontSize: "30px"}}/>
                    }
                </>
                }
                <Box container sx={{marginTop: "10px"}}>
                    <Grid container sx={{overflow: "hidden"}}>
                        <Grid item xs={2} sx={{}}>
                        {recipeData.recipePictureUrl &&
                        <img src={recipeData.recipePictureUrl} className="recipePicture"/>
                        }
                        </Grid>
                        <Grid item container xs={9} sx={{marginLeft: "25px", paddingLeft: "25px", display: "block"}}>
                        <h3 style={{color:"#166FFF", width: "100%", marginTop: "0px", marginBottom: "0px", fontSize: "18px", display: "block"}}>{recipeData.type.toUpperCase()}</h3>
                        <h3 style={{marginTop: "0", height: "46px", overflow: "hidden"}}>{recipeData.title}</h3>
                        </Grid>
                    </Grid>
                    <Grid container sx={{fontSize: "15px", color: "grey", marginTop: "10px"}} spacing={2}>
                            <Grid item xs={6}>
                                <Box sx={{backgroundColor: "rgba(235, 248, 255, 0.8)", borderRadius: "19px", textAlign: "center"}}>
                            <RestaurantOutlinedIcon sx={{width: "20px", verticalAlign:"middle"}}/> <p style={{marginTop: "10px", display: "inline-block"}}>{`${recipeData.prepTime} mins`}</p>
                            </Box>
                            </Grid>
                            <Grid item xs={6}>
                            <Box sx={{backgroundColor: "rgba(255, 175, 101, 0.3)", borderRadius: "19px", textAlign: "center"}}>
                            <LocalFireDepartmentIcon sx={{width: "20px", verticalAlign: "middle", display: "inline-block"}}/> <p style={{marginTop: "10px", display: "inline-block"}}>{`${recipeData.cookTime} mins`}</p>
                            </Box>
                            </Grid>
                        </Grid>
                    <Box container>
                        {recipeData.summary &&
                            <Box>
                                <p>{recipeData.summary}</p>
                            </Box>
                        }
                        <Box>
                            <p style={{fontStyle: "italic"}}>{`Servings ${recipeData.servings}`}</p>
                        </Box>
                        <Box>
                        {recipeData.tags.map((tag => {
                        return <p style={{backgroundColor: "rgba(75,75,75,0.1)", padding: "8px 15px", borderRadius: "10px", display: "inline-block", marginRight: "10px"}}>{tag}</p>
                    }))}
                    </Box>
                        <Box item xs={12}>
                            <h2 style={{marginTop: "8px"}}>Ingredients</h2>
                            {recipeData &&
                            recipeData.ingredients.map((element, indexOf) => {

                                let borderType =  "1px solid lightgrey";
                                if(indexOf == recipeData.ingredients.length - 1) {
                                    borderType = "none"
                                }

                                return (
                                    <Box key={indexOf} sx={{borderBottom: borderType, float: "clear", position: "relative"}}>
                                    <p><span style={{display: "inline-block", maxWidth: "60vw"}}>{element.ingredient}</span><span style={{position: "absolute", right: "0px"}}>{`${element.amount} ${element.measurement}`}</span></p>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box item xs={12} sx={{textAlign: "left"}}>
                            <h2>Instructions</h2>
                            {recipeData && 
                            recipeData.instructions.map((element, indexOf) => {
                                return (
                                    <p key={indexOf} style={{margin: "15px 0px"}}>{`Step #${indexOf + 1}: ${element}`}</p>
                                )
                            })}
                        </Box>
                        <h2 style={{marginBottom: "10px"}}>Created By</h2>
                        <p>{recipeData.userUsername}</p>
                        <h2 style={{marginBottom: "10px"}}>Source</h2>
                        <p style={{fontStyle: "italic"}}>{source}</p>
                    </Box>
                </Box>
                {currentUserRecipe && 
                                <Link to={`/editRecipe/${props.recipeId}`}><Button variant="contained" sx={{width: "100%"}}>Edit Recipe</Button></Link>}
                </Container>
            }
            </>
        )
}

export default ViewRecipe