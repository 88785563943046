import { app } from "../firebase/firebaseConfig"
import {getFirestore,
        doc,
        collection,
        addDoc,
        query,
        where,
        getDoc,
        getDocs,
        setDoc,
        updateDoc,
        arrayUnion,
        arrayRemove,
        serverTimestamp,
        deleteDoc } from "firebase/firestore";
import { changeEmail } from "../firebase/authFunctions";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getRecipe, changeFavoriteCount } from "./RecipeController";

const db = getFirestore(app);

export async  function addUser(username, firstName, lastName, email, firebaseAuthId) {

    if(!username) {
        throw "no username"
    } else if(!firstName) {
        throw "no first name"
    } else if (!lastName) {
        throw "no last name"
    } else if (!email) {
        throw "no email"
    } else {
        // 3929210682228
        const dbRes = await addDoc(collection(db, "users"), {
            username,
            firstName,
            lastName,
            email,
            firebaseAuthId,
            sousChefs: [],
            createdDateTime: serverTimestamp(),
            profilePicturePath: "profilePicture/3929210682228",
            profilePictureURL: "https://firebasestorage.googleapis.com/v0/b/hotpatata-da973.appspot.com/o/profilePictures%2F3929210682228?alt=media&token=eaef9d39-dce5-4da2-bbed-38ef74675fd0"

        })

        return dbRes    
    }
}

// export async function getUserByDbId (DbId) {
//     const docRef = doc(db, "users", DbId);
//     const docSnap = await getDoc(docRef);

//     let docReturn = docSnap.data()
// }   

export async function getUserByFirebaseId(uid) {
    const userRef= collection(db, "users")
    const q = query(userRef, where("firebaseAuthId", "==", uid));

    const dbRes = await getDocs(q)

    return dbRes;

}

export async function checkIfUsernameAvailable(currentUsername, username) {
    if(currentUsername !== username) {
    const userRef= collection(db, "users")
    const q = query(userRef, where("username", "==", username));

    const dbRes = await getDocs(q)

    let docsReturn = []

    dbRes.forEach((doc) => {
        docsReturn.push(doc.data())
    })

    if(docsReturn.length !== 0) {
        throw "username exists"
    }

    return dbRes
} else return

}

export async function updateUser(username, firstName, lastName, email, location, summary, dbUserDocId, profilePicturePath, profilePictureURL) {
    
    const userDocRef = doc(db, "users", dbUserDocId)

    if(!username || !firstName || !lastName) {
        throw "error"
    }

    if(!profilePicturePath) {
        profilePicturePath = ""
    }
    if(!profilePictureURL) {
        profilePictureURL = ""
    }
    if(!location) {
        location = ""
    }
    if(!summary) {
        summary = ""
    }

    const dbRes = await updateDoc(userDocRef, {
        username,
        firstName,
        lastName,
        email,
        profilePicturePath,
        profilePictureURL,
        location,
        summary
    })

    return dbRes;
}

export async function addSousChef (userDbId, sousChefDbId) {
    console.log(userDbId, sousChefDbId)
    const userRef = doc(db, "users", userDbId)

    const dbRes = await updateDoc(userRef, {
        sousChefs: arrayUnion(sousChefDbId)
    })

    return dbRes
}

export async function removeSousChef (userDbId, sousChefDbId) {
    const userRef = doc(db, "users", userDbId)

    const dbRes = await updateDoc(userRef, {
        sousChefs: arrayRemove(sousChefDbId)
    })

    return dbRes
}



export async function getSousChefs(sousChefEmails) {
    const userRef= collection(db, "users")
    const q = query(userRef, where("email" , "in" , sousChefEmails));

    const dbRes = await getDocs(q)
    return dbRes;
}

export async function getAllUsers(notIncludeArray) {
    const userRef= collection(db, "users")
    const q = query(userRef, where("email", "not-in", notIncludeArray))
    const users = []
    const dbRes = await getDocs(q)
    dbRes.forEach((doc) => {
        let userInfo = doc.data();
        userInfo.dbId = doc.id
        users.push(userInfo)
    })
    return users;
}

export async function addRecipeToUser(dbUserDocId, recipeDbId) {
    const userDocRef = doc(db, "users", dbUserDocId)

    const dbRes = await updateDoc(userDocRef, {
        recipes: arrayUnion(recipeDbId)
    })
}

export async function getUser(dbId) {
    const docRef = doc(db, "users", dbId);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  return docSnap.data()
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
}

export async function addRecipeToFavorites (userDbId, recipeDbId) {
    const userRef = doc(db, "users", userDbId)

    const dbRes = await updateDoc(userRef, {
        favoriteRecipes: arrayUnion(recipeDbId)
    })

    changeFavoriteCount(recipeDbId, "add")

    return dbRes
}

export async function removeRecipeFromFavorites (userDbId, recipeDbId) {
    const userRef = doc(db, "users", userDbId)

    const dbRes = await updateDoc(userRef, {
        favoriteRecipes: arrayRemove(recipeDbId)
    })

    changeFavoriteCount(recipeDbId, "remove")

    return dbRes
}