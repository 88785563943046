import React, {setState, useState} from "react"
import { generateRecipes } from "../firebase/aiFunctions";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button";

const RecipeCreatorForm = () => {

    const [numRecipes, setNumRecipes] = useState(3);

    const handleNumRecipeChange = (event) => {
        setNumRecipes(event.target.value);
    };

    const createRecipes = () => {
      const recipes = generateRecipes(numRecipes)
      console.log(recipes)
    }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="numRecipes">Number of Recipes Needed</InputLabel>
        <Select
          labelId="numRecipes"
          value={numRecipes}
          label="numRecipes"
          onChange={handleNumRecipeChange}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
      </FormControl>
      <Button onClick={() => createRecipes()}variant="text">Submit</Button>
    </Box>
  );

}

export default RecipeCreatorForm;