import React from "react"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
 
const PageHeader = (props) => {

    const navigate = useNavigate()

    const handleNavigation = () => {
        if(props.handleCancelEdit) {
            props.handleCancelEdit()
        } else {
            navigate(props.sendTo)
        }
    }
    return (
        <Grid container sx={{alignItems: "center"}}>
        <Grid item xs={2}>
        <Button onClick={handleNavigation}><ArrowBackIosNewIcon sx={{color: "#166FFF", border: "1px solid lightgrey", padding: "5px", borderRadius: "10px"}}/></Button>
        </Grid>
        <Grid item xs={8}>
            <h2 style={{textAlign: "center"}}>{props.pageTitle}</h2>
        </Grid>
    </Grid>
    )
}

export default PageHeader