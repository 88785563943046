import { app } from "./firebaseConfig"
import { getAuth , 
        createUserWithEmailAndPassword , 
        signInWithEmailAndPassword,
        updateEmail,
        sendPasswordResetEmail,
        signOut} from "firebase/auth";
import { addUser } from "../controllers/UserController"

const auth = getAuth(app);

export async function createNewUserWithEmailAndPassword (firstName, lastName, email, password) {
    const authRes = await createUserWithEmailAndPassword(auth, email, password)

    return authRes
}

export function signInUserWithEmailAndPassword (email, password) {
    return signInWithEmailAndPassword(auth, email, password)
}

export function signOutUser() {
    signOut(auth)
    .catch((err) => {
        console.log(err )
    })
}

export function changeEmail(email) {
    updateEmail(auth.currentUser, email)
    .catch(err => console.log(err))
}

export function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email)
}

