const typeFilter = (recipeList, typeSelection) => {
    if(typeSelection !== "All" && typeSelection) {
    const filteredRecipesByType = recipeList.filter((recipe) => {
        return recipe.type === typeSelection
    })
    return filteredRecipesByType
} else {
    return recipeList
}
}

const tagFilter = (recipeList, tagSelection) => {
    const filteredRecipesByTag = recipeList.filter((recipe) => {
        for(let i=0; i < tagSelection.length; i++) {
            if(recipe.tags.indexOf(tagSelection[i]) === -1) {
                return false
            }
        }

        return true
    })
    return filteredRecipesByTag
}

const searchStringFilter = (recipeList, searchString) => {
    const filteredRecipesBySearchString = recipeList.filter((recipe) => {
        const toLowercaseTitle = recipe.title.toLowerCase()
        return toLowercaseTitle.includes(searchString.toLowerCase())
    })
    return filteredRecipesBySearchString
}

const filterHandler = (recipeList, typeSelection, tagSelection, searchString) => {
    const filteredByTypeArr = typeFilter(recipeList, typeSelection)
    const filteredByTagArr = tagFilter(filteredByTypeArr, tagSelection)
    const filteredBySearchString = searchStringFilter(filteredByTagArr, searchString)
    return filteredBySearchString
}

const showRecipeFilter = (recipeList, favoriteRecipes, userDbId, showRecipeSelection, typeSelection, tagSelection, searchString) => {
    if(recipeList) {
    if(showRecipeSelection === "all") {
        return filterHandler(recipeList, typeSelection, tagSelection, searchString)
    } else if(showRecipeSelection === "favorites") {
        const filteredFavorites = recipeList.filter((recipe) => {
            return favoriteRecipes.indexOf(recipe.dbId) !== -1
        })
        return filterHandler(filteredFavorites, typeSelection, tagSelection, searchString)   
    } else if(showRecipeSelection === "own") {
        const filteredOwn = recipeList.filter((recipe) => {
            return recipe.userDbId === userDbId
          })
          return filterHandler(filteredOwn, typeSelection, tagSelection, searchString)
    }
}
}

export default showRecipeFilter