import React from "react"
import { useParams } from "react-router"
import ViewSousChef from "./ViewSousChef"


export default function ViewSousChefWrapper (props) {

    let params = useParams()
    return (
        <ViewSousChef 
            userDbId={props.loggedInUserDbId} 
            sousChefUserDbId={params.dbId}
            userSousChefs={props.currentUserSousChefs}
            title={props.title}
        />
    )

}
