import { app } from "../firebase/firebaseConfig"
import {getFirestore,
        collection,
        doc,
        addDoc,
        getDocs,
        getDoc,
        query,
        where,
        updateDoc,
        deleteDoc,
        orderBy,
        serverTimestamp,
        increment } from "firebase/firestore";
import { addRecipeToUser } from "./UserController";

const db = getFirestore(app);

export async function addRecipe(summary, type, title, prepTime, cookTime, servings, tags, ingredients, instructions, source, userPicture, userDbId, userUsername, recipePictureUrl) {

    if(!title) {
        throw "There is no title. You gotta name it!"
    } else if (!prepTime) {
        throw "The prep time is zero. You always need to do something..."
    } else if (servings < 1) {
        throw "There are no servings. If there are no servings, is there any food?"
    } else if (!userPicture) {
        throw "There was an error. Please try again."
    } else if (!userDbId) {
        throw "There was an error. Please try again."
    } else if (!type) {
        throw "There is no recipe type. How will anyone know what it is?"
    } else if (!source) {
        throw "There is no source. Even if you made it, take the credit!"
    }
    else {
        for(let i = 0; i < ingredients.length; i++) {
            if(ingredients[i].ingredient === "") {
                throw "One of your ingredients is blank. Please try again."
            }
        }
    
        for(let i = 0; i < instructions.length; i++) {
            if(instructions[i] === "") {
                throw "One of your instructions is blank. Please try again."
            }
        }
    }

        return await addDoc(collection(db, "recipes"), {
        summary,
        type,
        title,
        prepTime,
        cookTime,
        servings,
        tags,
        ingredients,
        instructions,
        source,
        userPicture,
        userDbId,
        createdDateTime: serverTimestamp(),
        userUsername
        })
    }

export async function getAllRecipes () {
    const allRecipes = []
    const q = query(collection(db, "recipes"), orderBy("createdDateTime", "desc"))
    const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            let recipeData = doc.data()
            recipeData.dbId = doc.id
            allRecipes.push(recipeData)
        })
    return allRecipes;
}

export async function getRecipe(dbId) {
    const docRef = doc(db, "recipes", dbId);
    const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  return docSnap.data()
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
}

export async function getUsersRecipes(userDbId) {
    let recipeArray = []
    const q = query(collection(db, "recipes"), where("userDbId", "==", userDbId), orderBy("userDbId"), orderBy("createdDateTime", "desc"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        let recipe = doc.data();    
        recipe.dbId = doc.id 
    // doc.data() is never undefined for query doc snapshots
    recipeArray.push(recipe)
    });

    return recipeArray;

}

export async function getLandingPageRecipes(recipes) {
    let recipeArray = []
    const recipeRef1 = doc(db, "recipes", recipes[0]);
    const recipeRef2 = doc(db, "recipes", recipes[1]);

    const querySnapshot1 = await getDoc(recipeRef1);
    recipeArray.push(querySnapshot1.data())
    const querySnapshot2 = await getDoc(recipeRef2);
    recipeArray.push(querySnapshot2.data())

    return recipeArray

}

async function updateRecipeProfilePicture(recipeDbId, profilePictureURL) {
    const recipeRef = doc(db, "recipes", recipeDbId)

    await updateDoc(recipeRef, {
        userPicture: profilePictureURL
    })
}

export async function updateProfilePictureOnRecipes(userDbId, profilePictureURL) {
    let recipeArray = []
    const q = query(collection(db, "recipes"), where("userDbId", "==", userDbId));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        updateRecipeProfilePicture(doc.id, profilePictureURL)
    // doc.data() is never undefined for query doc snapshots
    recipeArray.push(doc.data())
    });

    return recipeArray;

}

async function updateRecipeUsername(recipeDbId, username) {
    const recipeRef = doc(db, "recipes", recipeDbId)

    await updateDoc(recipeRef, {
        userUsername: username
    })
}

export async function updateUsernameOnRecipes(userDbId, username) {
    let recipeArray = []
    const q = query(collection(db, "recipes"), where("userDbId", "==", userDbId));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        updateRecipeUsername(doc.id, username)
    // doc.data() is never undefined for query doc snapshots
    recipeArray.push(doc.data())
    });

    return recipeArray;

}

export async function updateRecipe(dbId, updatedObjectInfo) {
    if(!updatedObjectInfo) {
        if(!updatedObjectInfo.title) {
            throw "There is no title. Please try again"
        } else if (!updatedObjectInfo.prepTime) {
            throw "The prep time is zero. Please try again."
        } else if (!updatedObjectInfo.cookTime) {
            throw "The cook time is zero. Please try again."
        } else if (updatedObjectInfo.servings < 1) {
            throw "There are no servings. Please try again."
        } else if (!updatedObjectInfo.source) {
            throw "There is no source. Please try again"
        } else {
            for(let i = 0; i < updatedObjectInfo.ingredients.length; i++) {
                if(updatedObjectInfo.ingredients[i].ingredient === "") {
                    throw "One of your ingredients is blank. Please try again."
                }
            }
            for(let i = 0; i < updatedObjectInfo.instructions.length; i++) {
                if(updatedObjectInfo.instructions[i] === "") {
                    throw "One of your instructions is blank. Please try again."
                }
            }
        }
    }
    
    const recipeRef = doc(db, "recipes", dbId)

    const dbRes = await updateDoc(recipeRef, updatedObjectInfo)

    return dbRes

}

export async function changeFavoriteCount(recipeDbId, addRemove) {
    const recipeRef = doc(db, "recipes", recipeDbId);

    getRecipe(recipeDbId)
    .then(recipe => {
        let tempFavoriteCount = 0
        if(recipe.favoriteCount) {
            tempFavoriteCount = recipe.favoriteCount
        }

        if(addRemove === "add") {
            tempFavoriteCount++;
        } else if(addRemove === "remove" && tempFavoriteCount !== 0) {
            tempFavoriteCount--;
        }

        updateDoc(recipeRef, {
            favoriteCount: tempFavoriteCount
        })

    })

}

export async function deleteRecipe(dbId) {
    
    const dbRes = await deleteDoc(doc(db, "recipes", dbId))

    return dbRes

}