import React, { useState } from "react"
import { forgotPassword } from "../firebase/authFunctions"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert"
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const ForgotPassword = (props) => {

  document.title=props.title
    
  const [email, setEmail] = useState()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const submitForgotPassword = (e) => {
      e.preventDefault()
      forgotPassword(email)
      .then(res => {
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
        }, 5000)
      })
      .catch(err => {
        setError(true)
        if(err.code === "auth/missing-email") {
          setErrorMessage("You didn't input an email. Try again.")
        } else {
          setErrorMessage("There was an error. Validate your email is correct.")
        }
        setTimeout(() => {
          setError(false)
        }, 5000)
      })
  }

  return(
    <>
      <Container component="main" sx={{textAlign: "center"}}>
        {error &&
          <Alert sx={{marginTop: "20px 0px" }} severity="error">
            {errorMessage}
          </Alert>
        }
        {isSuccess &&
          <Alert sx={{margin: "20px 0px" }} severity="success">
            <p>{`Password reset email sent to ${email}`}</p>
          </Alert>
        }
        <Box sx={{mt: "80px", mb: "30px"}}>
          <Avatar sx={{ ml: "auto", mr: "auto", backgroundColor: "#166FFF"}}>
            <LockOutlinedIcon />
          </Avatar>
          <h1 style={{mt: "30px"}}>Forgot Password</h1>
          <Box component="form" sx={{
              marginTop: "35px",
              width: "100%"
          }}>
            <TextField id="email" onChange={(e) => setEmail(e.target.value)} label="Email" variant="outlined" sx={{
                mb: 2,
                width: "100%"}}/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor:"#166FFF" }}
              onClick={(e) => submitForgotPassword(e)}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link  style={{textDecoration: "none"}} href="/signIn" variant="body2">
                  Back to sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <p style={{marginTop: "30px",textAlign: "center", width: "100vw"}}>&copy; {new Date().getFullYear()} HotPatata</p>
    </>
  )
}

export default ForgotPassword