import React, {useState, useEffect} from "react"

import { Container, Box, Paper } from "@mui/material"

import { getUser } from "../controllers/UserController"
import { getUsersRecipes } from "../controllers/RecipeController"
import AuthPagesWrapper from "./AuthPagesWrapper"
import RecipeCard from "./RecipeCard"
import Button from "@mui/material/Button"
import AddIcon from '@mui/icons-material/Add';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Grid from "@mui/material/Grid"
import { Link } from "react-router-dom"
import { addSousChef, removeSousChef } from "../controllers/UserController"
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

const ViewSousChef = (props) => {

    document.title = props.title

    const [userInfo, setUserData] = useState({
        location: "",
        summary: ""
    })
    const [userRecipes, setUserRecipes] = useState([])
    const [alreadySousChef, setAlreadySousChef] = useState(false)
    const [recipesPrepared, setRecipesPrepared] = useState(0)
    const [userName, setUserName] = useState("")

    useEffect(() => {
        getUser(props.sousChefUserDbId)
        .then(user => {
            setUserData(user)
            setUserName(user.username)
            getUsersRecipes(props.sousChefUserDbId)
            .then(dbRes => {
                setRecipesPrepared(dbRes.length)
                setUserRecipes(dbRes)
            })
        })
        if(props.userSousChefs.indexOf(props.sousChefUserDbId) !== -1) {
            setAlreadySousChef(true)
        }
    }, [])

    const handleAddSousChef = () => {
        addSousChef(props.userDbId, props.sousChefUserDbId)
        .then(() => {
            logEvent(analytics, "add_sous_chef")
            setAlreadySousChef(true)
        })
    }

    const handleRemoveSousChef = () => {
        removeSousChef(props.userDbId, props.sousChefUserDbId)
        .then(() => {
            logEvent(analytics, "remove_sous_chef")
            setAlreadySousChef(false)
        })
    }

    let viewingYourself = false

    if(props.userDbId === props.sousChefUserDbId) {
        viewingYourself = true
    }

        return(
            <Box className={"backgroundBox"}>
            <AuthPagesWrapper>
                <Container sx={{backgroundColor: "rgb(248,248,248)"}}>
            <Grid container sx={{paddingTop: "78px", paddingBottom: "68px", minHeight: "100vh", position: "relative"}}>
            {viewingYourself ?
            <Link to="/profile"><Button sx={{position: "absolute", right:"0px"}}>Edit User</Button></Link>
            :
            <>
                {alreadySousChef ?
                <Button onClick={handleRemoveSousChef} color="primary" variant="contained" sx={{position: "absolute", right: "0px"}}><LinkOffIcon sx={{marginRight: "8px"}}/>  Remove Sous Chef</Button>
                :
                <Button onClick={handleAddSousChef} color="primary" variant="contained" sx={{position: "absolute", right: "0px"}}><AddIcon sx={{marginRight: "8px"}}/>  Add Sous Chef</Button>
                }
                </>
            }
                <Paper sx={{height: "100%", width: "100%", padding: "15px", marginTop: "65px", border: "1px solid #873F90"}}>
                <Grid container sx={{overflow: "hidden"}}>   
                <Grid sx={{textAlign: "center"}} item xs={5} md={3}>
                <img style={{height: "100px", textAlign: "center", width: "100px"}}src={userInfo.profilePictureURL}/>
                </Grid>
                <Grid item xs={7} md={9}>
                <h2 style={{marginTop: 0, marginBottom: "8px"}}>{userName}</h2>
                {userInfo.location &&
                    <p style={{marginTop: "8px", fontStyle: "italic"}}>{userInfo.location}</p>
                }
                </Grid>
                <Grid item xs={12}>
                {userInfo.summary &&
                    <p style={{marginBottom: "0px", paddingTop: "10px", borderTop: "1px lightgrey solid"}}>{userInfo.summary}</p>
                }
                </Grid>
                </Grid>
                </Paper>
                <p style={{fontSize: "25px", marginBottom: "0px"}}>{`Recipes (${recipesPrepared})`}</p>
                {userRecipes.length > 0 ?   
                userRecipes.map((recipe) => {
                    return(
                        <RecipeCard
                        key={recipe.dbId}
                        recipeTitle={recipe.title}
                        prepTime={recipe.prepTime}
                        cookTime={recipe.cookTime}
                        recipeDbId={recipe.dbId}
                        userPicture={recipe.userPicture}
                        recipePicture={recipe.recipePictureUrl}
                        recipeTags={recipe.tags}
                        username={recipe.userUsername}
                        showAddButton={false}
                    />
                    )
                })
                :
                <p>No recipes yet!</p>
                }
                
            </Grid>
            </Container>
            </AuthPagesWrapper>
            </Box>
        )
    }

export default ViewSousChef