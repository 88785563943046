const recipeTypes = [
    "Main Dish",
    "Side",
    "Appetizer",
    "Snack",
    "Breakfast / Brunch",
    "Dessert",
    "Marinade/Rub",
    "Drink"
]

export default recipeTypes