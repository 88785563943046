import React, {useState} from "react"
import { signInUserWithEmailAndPassword } from "../firebase/authFunctions"

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Alert from '@mui/material/Alert'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

const SignIn = (props) => {

  document.title=props.title
  
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const submitSignIn = (e) => {
      e.preventDefault()
      signInUserWithEmailAndPassword(email, password)
      .then(() => logEvent(analytics, "sign_in"))
      .catch(err => {
        console.log(err.code)
        setError(true)
        if(err.code === "auth/missing-email") {
          setErrorMessage("You didn't input an email. Try again.")
        } else if (err.code === "auth/invalid-login-credentials") {
          setErrorMessage("Your email or password is incorrect. Please check and try again.")
        } else if (err.code === "auth/user-not-found") {
          setErrorMessage("No user found with that email. Please check again try again.")
        } else {
          setErrorMessage("There was an error. Please refresh the page and try again.")
        }
        setTimeout(() => {
          setError(false)
        }, 5000)
      })

  }
  return(
    <>
      <Box component="main" sx={{textAlign: "center", backgroundColor: "#166FFF", padding: "10px 20px", minHeight: "100vh"}}>
        <a href="/" style={{textDecoration: "none"}}><h1 className="oregano-regular" style={{textAlign: "center", color: "white", fontSize: "45px", margin: 0}}>Hotpatata</h1></a>
        <Paper sx={{ textAlign: "center", padding: "15px", marginTop: "15px" }}>
        {error &&
            <Alert sx={{margin: "20px 0px" }} severity="error">
              {errorMessage}
            </Alert>
          }
          <form>
          <TextField
            fullWidth
            id="email"
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            sx={{mb: 2}}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{mb: 2}}
          />
          <Button
          type="submit"
            variant="contained"
            sx={{ mt: "15px", width: "100%"}}
            onClick={(e) => submitSignIn(e)}
          >
            Sign In
          </Button>
          </form>
          <Grid container sx={{mt: "15px", mb: 2, textAlign: "center"}}>
            <Grid item xs={6} sx={{textAlign: "center"}}>
              <Link  style={{textDecoration: "none"}} href="/forgotPassword" variant="body2">
                <p style={{color: "grey", textDecoration: "none"}}>Forgot password?</p>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link  style={{textDecoration: "none"}} href="/signUp" variant="body2">
                <p style={{color: "grey", textDecoration: "none"}}>Don't have an account? Sign Up</p>
              </Link>
            </Grid>
          </Grid>
        </Paper>
        <p style={{ textAlign: "center", color: "white", width: "100vw"}}>&copy; {new Date().getFullYear()} HotPatata</p> 
      </Box>
      </>
  )
}

export default SignIn