import React, { useState, useEffect } from 'react';
import './index.css';
import { Routes, Route, Navigate, Switch, useNavigate } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getUserByFirebaseId } from './controllers/UserController';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toDate } from "firebase/firestore"

// Components
import HomePage from "./components/HomePage"
import ViewRecipeWrapper from './components/ViewRecipeWrapper';
import AddRecipe from './components/AddRecipe';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import NotFound from "./components/NotFound";
import UserProfile from "./components/UserProfile";
import ForgotPassword from "./components/ForgotPassword";
import ModifyRecipeWrapper from './components/ModifyRecipeWrapper';
import SousChefPage from "./components/SousChefPage";
import ViewSousChefWrapper from './components/ViewSousChefWrapper';

const theme = createTheme({
  palette: {
      primary: {
          main: "#166FFF",
          contrastText: "#FFF"
      }
  }
});

const App = () => {

  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [loadReady, setLoadReady] = useState(false)
  const [userDbId, setUserDbId] = useState("")
  const [userData, setUserData] = useState("")
  const [firebaseAuthId, setFirebaseAuthId] = useState("")
  const [homePageSelection, setHomePageSelection] = useState("all")
  const [firstLogin, setFirstLogin] = useState(false)
  const [prevLocation, setPrevLocation] = useState("")

  useEffect(() => {
    const auth = getAuth()
    
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setFirebaseAuthId(user.uid)
          setUserLoggedIn(true)
          getUserByFirebaseId(user.uid)
          .then(dbRes => {
            dbRes.forEach((doc) => {
              setUserDbId(doc.id)
              setUserData(doc.data())
              if(doc.data().createdDateTime.seconds + 1800 > new Date().getTime()/1000) {
                setFirstLogin(true)
              }
            })
              setLoadReady(true)
          })
       } else {
          setUserLoggedIn(false)
          setLoadReady(true)
        }
      })
    },[])

    return (
      <ThemeProvider theme={theme}>
        {loadReady ?
          userLoggedIn ? 
          // AUTH
          <Routes>
            <Route path="/" element={<Navigate to="/home"/>}/>
            <Route path="/home" element={<HomePage 
            userDbId={userDbId}
            userFavoriteRecipes={userData.favoriteRecipes}
            sousChefs={userData.sousChefs}
            firstName={userData.firstName}
            handleHomePageSelection={setHomePageSelection}
            homePageSelection={homePageSelection}
            title="HotPatata | Home"
            firstLogin={firstLogin}
            
            />}/>
            <Route path="/addRecipe" element={<AddRecipe
              userDbId={userDbId}
              userProfilePictureUrl={userData.profilePictureURL}
              username={userData.username}
              title="HotPatata | Add Recipe"
              prepTime={0}
              cookTime={0}
              ingredients={['']}
              instructions={['']}
              notes={['']}
              />}/>
            <Route path="/viewSousChef/:dbId" element={<ViewSousChefWrapper
            loggedInUserDbId={userDbId}
            currentUserSousChefs={userData.sousChefs}
            title="HotPatata | View Sous Chef"
            />}/>
            {/* <Route path="/sousChefs" element={<SousChefPage
              userDbId={userDbId}
              userEmail={userData.email}
              currentUserSousChefs={userData.sousChefs}
              />}/> */}
            <Route path="/editRecipe/:recipeId" element={<ModifyRecipeWrapper
            userDbId={userDbId}
            userProfilePictureUrl={userData.profilePictureURL}
            title="HotPatata | Edit Recipe"
            />}/>
            <Route path="/viewRecipe/:recipeId" element={<ViewRecipeWrapper 
            userDbId={userDbId}
            title="HotPatata | View Recipe"
            auth={userLoggedIn}
            />}/>
            <Route path="/profile" element={<UserProfile 
              userDbId={userDbId} 
              userData={userData}
              firebaseAuthId={firebaseAuthId}
              title="HotPatata | Profile"/>}/>
            <Route path="/signIn" element={<Navigate to="/"/>} />
            <Route path="/signUp" element={<Navigate to="/"/>} />
            <Route path="*" element={<NotFound title="HotPatata | 404"/>}/>
          </Routes>
          :
          // NO AUTH
            <Routes>
              <Route path="/" element={<LandingPage title="HotPatata"/>} />
              <Route path="/signIn" element={<SignIn title="HotPatata | Sign In"/>} />
              <Route path="/signUp" element={<SignUp title="HotPatata | Sign Up"/>} />
              <Route path="/forgotPassword" element={<ForgotPassword title="Hot Patata | Forgot Password"/>} />
              <Route path="/viewRecipe/:recipeId" element={<ViewRecipeWrapper 
                userFavoriteRecipes={userData.favoriteRecipes}
                title="HotPatata | View Recipe"
                auth={false}
              />}/>
              <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
          :
          <></>}
          </ThemeProvider>
    );
}

export default App;
