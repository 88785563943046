import React from "react"
import { useParams } from "react-router"
import ViewRecipe from "./ViewRecipe"


export default function ViewRecipeWrapper (props) {

    let params = useParams()

    return (
        <ViewRecipe 
        recipeId={params.recipeId} 
        userDbId={props.userDbId}
        userFavoriteRecipes={props.userFavoriteRecipes}
        title={props.title}
        auth={props.auth}
        />
    )

}
