import React from "react"

import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {Link} from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            // previously #A64D79
            main: "#166FFF",
            contrastText: "#FFF"
        }
    }
});

const AuthPagesWrapper = (props) => {

    return (
        <ThemeProvider theme={theme}>
            {props.children}
            <Box sx={{backgroundColor: "white", width: "100%", position: "fixed", bottom: 0, zIndex:"9999"}}>
            <BottomNavigation
                showLabels
                value={props.highlighted}
                sx={{width: "100%", boxShadow: "0px -1px 3px lightgrey", backgroundColor: "rgba(256,256,256,1)", pl: "0px", pr: "0px", fontSize: ".75rem"}}
            >
                <BottomNavigationAction href="/home" icon={<HomeOutlinedIcon />} />
                <BottomNavigationAction href="/addRecipe"icon={<AddCircleOutlineIcon />} />
                {/* <BottomNavigationAction href="/sousChefs" label="Sous Chefs" icon={<PeopleOutlineIcon />} /> */}
                <BottomNavigationAction href="/profile" icon={<AccountCircleOutlinedIcon />} />
            </BottomNavigation>
            </Box>
        </ThemeProvider>
    )
}

export default AuthPagesWrapper;