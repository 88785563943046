import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "./firebaseConfig";

export function addProfilePicture (fileName, fileData, fileType) {
    const profilePicRef = ref(storage, `profilePictures/${fileName}`)
    const metadata = {
        contentType: fileType
    }
    return uploadBytes(profilePicRef, fileData, metadata)
}

export function addRecipePicture (fileName, fileData, fileType) {
    const recipePicRef = ref(storage, `recipePictures/${fileName}`)
    const metadata = {
        contentType: fileType
    }
    return uploadBytes(recipePicRef, fileData, metadata)
}

export function getDownloadURLForPicture (storageLocation) {
    return getDownloadURL(ref(storage, storageLocation))
}

export async function deleteImage (fullPath) {
        const desertRef = ref(storage, fullPath);

        const deletion = await deleteObject(desertRef)

        return deletion
}