import React, {useState, useEffect} from "react"
import { getAllRecipes } from "../controllers/RecipeController"
import {Link} from "react-router-dom"
import RecipeCard from "./RecipeCard"
import { useTheme } from '@mui/material/styles';
import { getUser, removeSousChef } from "../controllers/UserController"
import { getAnalytics, logEvent } from "firebase/analytics";
import showRecipeFilter from "./functions/searchFunctions"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import List from '@mui/material/List';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material"
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import recipeTypes from "./datapoints/recipeTypes"
import Tags from "./datapoints/tags";
import Chip from "@mui/material/Chip";
import CircularProgress from '@mui/material/CircularProgress';

const analytics = getAnalytics();

  function getStyles(tags, tagName, theme) {
    return {
      fontWeight:
        tagName.indexOf(tags) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const HomePage = (props) => {
    
    const [recipes, setRecipes] = useState() 
    const [recipeType, setRecipeType] = useState("")
    const [tags, setTagName] = useState([]  )
    const [showSelection, setShowSelection] = useState("all")
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("")
    const [favoriteRecipes, setFavoriteRecipes] = useState([])
    const [searchString, setSearchString] = useState("")
    const [searchedRecipes, setSearchedRecipes] = useState([])
    const [userProfilePicture, setUserProfilePicture] = useState("")
    const [open, setOpen] = useState(false);
    const [favoritesChanged, setFavoritesChanged] = useState(false);
    const [loadReady, setLoadReady] = useState(false)

    const theme = useTheme();

    document.title = props.title

    useEffect(() => {

      setTimeout(() => {
      if(props.userDbId) {
      getUser(props.userDbId)
      .then(user => {
        setUserProfilePicture(user.profilePictureURL)
        if(user.favoriteRecipes) {
        setFavoriteRecipes(user.favoriteRecipes)
        }
      })
    }
      getAllRecipes()
      .then((returnedRecipes) => {
        setRecipes(returnedRecipes)
        setLoadReady(true)
    })}, 500)}
    , [favoritesChanged, props.userDbId])

  // Search function useEffect

  useEffect(() => {
    const filteredRecipes = showRecipeFilter(recipes, favoriteRecipes, props.userDbId, showSelection, recipeType, tags, searchString)
    setSearchedRecipes(filteredRecipes)
  }, [tags, showSelection, recipeType, searchString, recipes])

  const changedFavorites = () => {
    setFavoritesChanged(!favoritesChanged)
}

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const handleShowMeSelection = (e) => {
    setShowSelection(e.target.value)
  }

  const handleRecipeTypeSelection = (e) => {
    setRecipeType(e.target.value)
  }

  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setTagName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

// For recipe card

const handleClick = () => {
  setOpen(!open);
};

    return(
          <Container sx={{paddingTop: "20px", marginBottom: "20px"}}>
            <Link to="/addRecipe"><AddIcon className="addIcon" sx={{  position: "fixed",
  bottom: "20px",
  right: "20px", 
  zIndex: "100",
  backgroundColor: "#166FFF",
  padding: "10px",
  color: "white",
  borderRadius: "50%",
  fontSize: "30px",
  zIndex: 100}}/></Link>
  <Grid container spacing={1}>
          <Grid item xs={8}>
            {props.firstLogin ?
            <>
          <h2 style={{fontSize: "25px", fontWeight: "600"}}>Welcome to Hotpatata, {props.firstName}!</h2>
          </>
          :
          <h2 style={{fontSize: "25px", fontWeight: "600"}}>Welcome back to Hotpatata, {props.firstName}!</h2>
            }
          </Grid>
          <Grid item xs={4} sx={{alignContent: "center"}}>
            <Link to="/profile"><img style={{height: "50px", borderRadius: "50%", float: "right"}} src={userProfilePicture}/></Link>
          </Grid>
          </Grid>
          <TextField           
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon sx={{color: "#166FFF"}}/></InputAdornment>,
              disableUnderline: true
            }}
            onChange={handleSearch}
          placeholder="Search"
          variant="standard"
          sx={{padding: "10px", margin: "15px 0px 0px 0px", width: "100%", backgroundColor: "#EBF8FF", border: "none", borderRadius: "5px", boxSizing: "border-box"}}/>
              <Box sx={{width: "100%"}}>
              <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton sx={{paddingLeft: "0px", width: "100%"}} onClick={handleClick}>
      <ListItemText primary="More Filters" sx={{paddingLeft: "0px", color: "#166FFF"}}/>
      {open ? <ExpandLess sx={{color: "#166FFF"}} /> : <ExpandMore sx={{color: "#166FFF"}}/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 0, paddingRight: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
            <FormControl sx={{width: "100%"}}>
            <InputLabel id="show">Show</InputLabel>
            <Select
            labelId="show"
            value={showSelection}
            label="Show"
            sx={{borderRadius: "5px", width: "100%"}}
            onChange={handleShowMeSelection}
            >
              <MenuItem value="all">All Recipes</MenuItem>
              <MenuItem value="favorites">Favorites</MenuItem>
              <MenuItem value="own">My Own</MenuItem>
            </Select>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl sx={{width: "100%"}}>
            <InputLabel id="type">Recipe Type</InputLabel>
            <Select
            labelId="type"
            value={recipeType}
            label="Recipe Type"
            sx={{borderRadius: "5px", width: "100%"}}
            onChange={handleRecipeTypeSelection}
            >
              <MenuItem value="All" default>All</MenuItem>
              {recipeTypes.map((recipeType) => {
                return <MenuItem value={recipeType}>{recipeType}</MenuItem>
                })}
            </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl sx={{width: "100%"}}>
            <InputLabel id="tags">Tags</InputLabel>
            <Select
            labelId="tags"
            value={tags}
            multiple
            label="Tags"
            sx={{borderRadius: "5px", width: "100%"}}
            onChange={handleTagChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            >
              {Tags.map(tag =>{
                return <MenuItem value={tag} styles={getStyles(tags, tag, theme)}>{tag}</MenuItem>
              })}
            </Select>
            </FormControl>
            </Grid>
            </Grid>
          </ListItemButton>
            </List>
      </Collapse>
    </List>
    </Box>
        <Grid container sx={{marginTop: "0px"}}>
        {loadReady ?
        <>
        {showMessage &&
          <p style={{backgroundColor: "rgb(240,240,240)", padding: "10px", borderRadius: "4px", border: "2px solid grey"}}><InfoOutlinedIcon sx={{verticalAlign: "middle", marginRight:"10px"}}/>{message}</p>
        }
        <Grid container spacing={2}>
        {searchedRecipes &&
            searchedRecipes.map((recipe) => {
              console.log(recipe.recipeDbId)
              let isFavorite = false;
              let isCurrentUserRecipe = false
              let favoriteCount = 0;
              if(recipe.favoriteCount) {
                favoriteCount = recipe.favoriteCount
              }

              if(favoriteRecipes.indexOf(recipe.dbId) !== -1) {
                isFavorite = true;
              }

              if(props.userDbId === recipe.userDbId) {
                isCurrentUserRecipe = true
              }

              if(!recipe.recipePictureUrl) {
                recipe.recipePictureUrl = ""
              }

                return(
                  <Grid item xs={6} sm={4} md={3}>
                    <RecipeCard
                        key={recipe.dbId}
                        recipeTitle={recipe.title}
                        prepTime={recipe.prepTime}
                        cookTime={recipe.cookTime}
                        recipeDbId={recipe.dbId}
                        userPicture={recipe.userPicture}
                        recipePicture={recipe.recipePictureUrl}
                        recipeTags={recipe.tags}
                        userUsername={recipe.userUsername}
                        recipeUserDbId={recipe.userDbId}
                        currentUserDbId={props.userDbId}
                        isCurrentUserRecipe={isCurrentUserRecipe}
                        isFavoriteRecipe={isFavorite}
                        type={recipe.type}
                        favoriteCount={favoriteCount}
                        changedFavorites={changedFavorites}
                        auth={true}
                    />
                    </Grid>
                )
            })
        }
        </Grid>
        </>
        :
        <CircularProgress sx={{margin: "auto"}}/>
      }
        </Grid>
        </Container>
    )
}

export default HomePage