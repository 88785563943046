import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box"
import CheckIcon from '@mui/icons-material/Check';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { addRecipeToFavorites, removeRecipeFromFavorites } from '../controllers/UserController';


const RecipeCard = (props) => {

  const [isFavoriteRecipe, setIsFavoriteRecipe] = useState(props.isFavoriteRecipe)

  const navigate = useNavigate();

  const handleClick = () => {
      navigate(`/viewRecipe/${props.recipeDbId}`)
  }

  const handleAddFavorite = (e) => {
    e.stopPropagation();
    addRecipeToFavorites(props.currentUserDbId, props.recipeDbId)
    setIsFavoriteRecipe(true)
    props.changedFavorites()
  }

  const handleRemoveFavorite = (e) => {
    e.stopPropagation();
    removeRecipeFromFavorites(props.currentUserDbId, props.recipeDbId)
    setIsFavoriteRecipe(false)
    props.changedFavorites()
  }

  useEffect(() => {

    let tagString = ""
  if(props.recipeTags && props.recipeTags.length > 0) {
      for(let i=0; i < props.recipeTags.length; i++) {
          if(i !== props.recipeTags.length - 1) {
              tagString += `${props.recipeTags[i]}, `
          } else {
              tagString += `${props.recipeTags[i]}`
          }
      } 
      
    }

  }, [])

  let cardHeight = ""

  if(props.fromLandingPage) {
    cardHeight = "140px"
  } else {
    cardHeight = "200px"
  }

  let textPaddingLeft = "0px"
  if(!props.recipePicture) {
    textPaddingLeft = "16px"
  }

  return (
<Card className="recipeCard" sx={{ height: "275px", position: "relative" }} onClick={(e) => handleClick(e)}>
  {props.auth &&
  <Box sx={{position: "absolute", top: "10px", right: "10px", color: "black", backgroundColor: "white", borderRadius: "4px", padding: "3px", textAlign: "center", border: "solid 1px grey"}}>
  {!props.isFavoriteRecipe ?
  <StarOutlineIcon onClick={handleAddFavorite}/>
  :
  <StarIcon onClick={handleRemoveFavorite}/>
  }
  <p style={{margin: 0}}>{props.favoriteCount}</p>
  </Box>
}
  {props.recipePicture &&
        <CardMedia
          component="img"
          height="140"
          image={props.recipePicture}
        />
  }
        <CardContent>
            <h2 style={{fontSize: "16px", marginTop: 0, marginBottom: 0, maxHeight: "40px"}}>{props.recipeTitle}</h2>
            <Grid container sx={{position: "absolute", bottom: "5px"}}>
              <Grid xs={5} item>
<RestaurantOutlinedIcon sx={{color: "#3B82F6", paddingRight: "3px", fontSize: "16px"}}/><p style={{fontSize: "14px", display: "inline-block"}}>{props.prepTime} min</p>
              </Grid>
              <Grid sx={5} item>
              <LocalFireDepartmentIcon sx={{color: "#3B82F6", paddingRight: "3px", fontSize: "16px"}}/><p style={{fontSize: "14px", display: "inline-block"}}>{props.cookTime} min</p> 
                </Grid>
            </Grid>
            {/* <p style={{margin: 0, position: "absolute", bottom: "10px"}}>by {props.userUsername}</p> */}
        </CardContent>  
    </Card>
  )
}

export default RecipeCard