import React from "react";
import { Link } from "react-router-dom"

const NotFound = (props) => {

    document.title=props.title
    return(
        <>
            <h1>Where you going? This is not a page.</h1>
            <Link to="/home">Go Home</Link>
        </>
    )
}

export default NotFound