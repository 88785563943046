import React, { useState } from "react"
import { createNewUserWithEmailAndPassword } from "../firebase/authFunctions"
import { addUser, checkIfUsernameAvailable } from "../controllers/UserController";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper"
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

const SignUp = (props) => {

  document.title=props.title

  const [username, setUsername] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")

  // For success/error alerts
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleSignUp = (e) => {
    e.preventDefault()
    if(password === passwordConfirm) {
      checkIfUsernameAvailable("", username)
      .then(() => {
        createNewUserWithEmailAndPassword(firstName, lastName, email, password)
        .then((apiRes) => {
          console.log(apiRes)
          addUser(username, firstName, lastName, email, apiRes.user.uid)
          .then(dbRes => {
            setIsSuccess(true)
            logEvent(analytics, "sign_up")
            setTimeout(() => {
              setIsSuccess(false)
            }, 5000)
          })
          .catch(err => console.log(err))
        })
        .catch((err) => {
          logEvent(analytics, err.code)
          console.log(err.code)
          if(err.code === "auth/missing-password") {
            setErrorMessage("You didn't input a password, try again.")
          } else if (err.code === "auth/admin-restricted-operation") {
            setErrorMessage("There was an error, try again.")
          } else if (err.code === "auth/email-already-in-use") {
            setErrorMessage("You already have a user with this email. Try logging in.")
          } else {
            setErrorMessage("There was an error. Please try again.")
          }
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 5000)
        })
      })
      .catch((err) => {
        logEvent(analytics, err)
        setErrorMessage("Username taken. Please try again.")
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 5000)
      })
    } else {
        setErrorMessage("Passwords don't match, try again.")
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 5000)
    }
  }

  return(
    <>
      <Box sx={{textAlign: "center", backgroundColor: "#166FFF", padding: "10px 20px", minHeight: "100vh", position: "relative"}} component="main">
        <a href="/" style={{textDecoration: "none"}}><h1 className="oregano-regular" style={{fontSize: "45px", color: "white", margin: "0px"}}>Hotpatata</h1></a>
        <Paper sx={{marginTop: "15px", textAlign: "center", backgroundColor: "white", padding: "15px"}}>
        {isSuccess &&
          <Alert sx={{margin: "20px 0px" }} icon={<CheckIcon fontSize="inherit" />} severity="success">
            User created successfully
          </Alert>
        }
        {error &&
          <Alert sx={{margin: "20px 0px" }} severity="error">
            {errorMessage}
          </Alert>
        }
        <TextField id="username" type="input" onChange={(e) => setUsername(e.target.value)} label="Username" value={username} variant="outlined" sx={{
            width: "100%",
            mb: 2}}/>
          <Grid container spacing={2}>
          <Grid xs={6} item>
          <TextField id="firstName" type="input" onChange={(e) => setFirstName(e.target.value)} label="First Name" value={firstName} variant="outlined" sx={{
            width: "100%",
            mb: 2}}/>
            </Grid>
            <Grid item xs={6}>
            <TextField id="lastName" type="input" onChange={(e) => setLastName(e.target.value)} label="Last Name" value={lastName} variant="outlined" sx={{
            width: "100%",
            mb: 2}}/>
            </Grid>
            </Grid>
          <TextField id="email" onChange={(e) => setEmail(e.target.value)} label="Email" variant="outlined" value={email} sx={{
            mb: 2,
            width: "100%"}}/>
          <TextField id="password" type="password" onChange={(e) => setPassword(e.target.value)} label="Password" value={password} variant="outlined" sx={{
            width: "100%",
            mb: 2}}/>
            <TextField id="passwordConfirm" type="password" onChange={(e) => setPasswordConfirm(e.target.value)} value={passwordConfirm} label="Confirm Password" variant="outlined" sx={{
            width: "100%",
            mb: 2}}/>
          <Button variant="contained" type="submit" sx={{ mt: "15px", mb: "15px", width: "100%", color: "white", fontSize: "16px", backgroundColor: "#166FFF" }} onClick={handleSignUp}>Sign Up</Button>

        <Grid item xs={12} sx={{color: "white"}}>
              <Link style={{textDecoration: "none"}} href="/signIn" variant="body2">
                <p style={{color: "grey"}}>Already have an account? Login here.</p>
              </Link>
            </Grid>
            </Paper>
      </Box>
              <p style={{color: "white", position: "absolute", bottom: "10px", textAlign: "center", width: "100vw"}}>&copy; {new Date().getFullYear()} HotPatata</p>
              </>
  )
}

export default SignUp