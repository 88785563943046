import React, {useEffect, useState} from "react"
import {
    updateUser } from "../controllers/UserController"
import { addProfilePicture, getDownloadURLForPicture, deleteImage } from "../firebase/storageFunctions"
import { signOutUser } from "../firebase/authFunctions";
import { getAllRecipes, updateProfilePictureOnRecipes, getUsersRecipes, updateUsernameOnRecipes } from "../controllers/RecipeController";
import { getUser, checkIfUsernameAvailable } from "../controllers/UserController";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import PageHeader from "./uiComponents/PageHeader";
import { Container } from "@mui/material";
import Alert from "@mui/material/Alert"
import TabsComponent from "./uiComponents/TabsComponent"
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{width: "100%"}}
      >
        {value === index && (
            <Box>
            {children}
            </Box>
        )}
      </div>
    );
  }

const UserProfile = (props) => {

    document.title=props.title

    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("")
    const [favoritesChanged, setFavoritesChanged] = useState(true)
    const [loadReady, setLoadReady] = useState(false)

let temporarySummaryLength = 0
let temporaryLocationLength = 0
if(props.userData.summary) {
    temporarySummaryLength = props.userData.summary.length
}
if(props.userData.location) {
    temporaryLocationLength = props.userData.location.length
}

    // Pass user state here
    const [userData, setUserData] = useState(props.userData)
    const [currentUsername, setCurrentUsername] = useState(props.userData.username)
    const [userDbId, setUserDbId] = useState(props.userDbId)
    const [editsMade, setEditsMade] = useState(false)
    const [userRecipes, setUserRecipes] = useState([])
    const [favoriteRecipes, setFavoriteRecipes] = useState([])
    const [totalSummaryCount, setTotalSummaryCount] = useState(temporarySummaryLength)
    const [totalLocationCount, setTotalLocationCount] = useState(temporaryLocationLength)
    const [value, setValue] = useState(0);
    const [forceRefresh, setForceRefresh] = useState("")
    const [inEdit, setInEdit] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
        let favoriteRecipesArr = []
        getUser(props.userDbId)
        .then((dbRes) => {
            if(dbRes.favoriteRecipes) {
            favoriteRecipesArr = dbRes.favoriteRecipes
            }
            if(props.firebaseAuthId === dbRes.firebaseAuthId) {
            setUserData(dbRes)
            getAllRecipes()
            .then(recipes => {
                const tempUserRecipes = recipes.filter(recipe => {
                    return recipe.userDbId === props.userDbId
                })
                if(tempUserRecipes) {
                setUserRecipes(tempUserRecipes)
                }

                const tempFavoriteRecipes = recipes.filter(recipe => {
                    return favoriteRecipesArr.indexOf(recipe.dbId) > -1
                })
                if(tempFavoriteRecipes) {
                setFavoriteRecipes(tempFavoriteRecipes)
                }

                setLoadReady(true)
            })
            }
        })
    }, 500)},[favoritesChanged])

    const changedFavorites = () => {
        setFavoritesChanged(!favoritesChanged)
    }

    const handleEdit = (e) => {
        setInEdit(true)
        if(e.target.id == "summary") {
            const summaryLength = e.target.value.length
            if(e.target.value.length <= 120) {
                setTotalSummaryCount(summaryLength)
                const tempUserData = {...userData}
                tempUserData.summary = e.target.value
                setUserData(tempUserData)
                setEditsMade(true)
            }
        }
        else if(e.target.id === "location") {
            const locationLength = e.target.value.length
            if(e.target.value.length <= 20) {
                setTotalLocationCount(locationLength)
                const tempUserData = {...userData}
                tempUserData.location = e.target.value
                setUserData(tempUserData)
                setEditsMade(true)
            }
        } else if (e.target.id !== "profilePicture") {
            const tempUserData = {...userData}
            if(e.target.id === "username") {
                tempUserData[e.target.id] = e.target.value.toLowerCase()
            } else {
                tempUserData[e.target.id] = e.target.value
            }
            setUserData(tempUserData)
            setEditsMade(true)
        }
        else {
            let profilePicturePath = ""
            let profPictureName = Math.floor(Math.random() * 10000000000000)
            if(userData.profilePicturePath && userData.profilePicturePath !== "profilePicture/3929210682228") {
                deleteImage(userData.profilePicturePath)
            }
            addProfilePicture(profPictureName, e.target.files[0], e.target.files[0].type)
            .then(snapshot => {
                profilePicturePath = snapshot.metadata.fullPath
                getDownloadURLForPicture(snapshot.metadata.fullPath)
                .then(downloadURL => {
                    const tempUserData = {...userData}
                    tempUserData.profilePictureURL = downloadURL
                    setUserData(tempUserData)
                    setEditsMade(true)
                    updateUser(tempUserData.username, tempUserData.firstName, tempUserData.lastName, tempUserData.email, tempUserData.location, tempUserData.summary, userDbId, profilePicturePath, downloadURL)
                    updateProfilePictureOnRecipes(userDbId, downloadURL)
                    .then(dbRes => {
                        setShowAlert(true)
                        setAlertMessage("Updated profile picture!")
                        setAlertSeverity("success")
                        setTimeout(() => {
                            setShowAlert(false)
                            let tempForceRefresh = forceRefresh
                            tempForceRefresh++;
                            setForceRefresh(tempForceRefresh)
                        }, 4000);
                    })
                })
            })
            .catch(err => {
                setShowAlert(true)
                setAlertMessage("There was an error. Please try again.")
                setAlertSeverity("error")
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            })
        }
    }
    
    const handleCancelEdit = () => {
        setInEdit(false)
        window.scrollTo(0,0)
    }

    const handleUpdateUser = (username,firstName, lastName, email, location, summary, userDbId, profilePicturePath, profilePictureURL) => {
    checkIfUsernameAvailable(currentUsername, username)
    .then(() => {
        updateUser(username, firstName, lastName, email, location, summary, userDbId, profilePicturePath, profilePictureURL)
        .then(dbRes => {
            updateUsernameOnRecipes(userDbId, username)
            setShowAlert(true)
            setAlertMessage("Updated user successfully!")
            setAlertSeverity("success")
            window.scrollTo(0,0)
            setTimeout(() => {
                setShowAlert(false)
            }, 5000)
        })
        .catch(err => {
            setShowAlert(true)
            setAlertMessage("There was an error. Please try again.")
            setAlertSeverity("error")
            window.scrollTo(0,0)
            setTimeout(() => {
                setShowAlert(false)
            }, 5000)
        })
    })
    .catch(() => {
        setShowAlert(true)
        setAlertMessage("The username is taken. Please try again.")
        setAlertSeverity("error")
        window.scrollTo(0,0)
        setTimeout(() => {
            setShowAlert(false)
        }, 5000)
    })
}

        return(
                <Container sx={{paddingTop: "20px", paddingBottom: "20px"}}>
                    {inEdit ?
                    <>
                        <PageHeader handleCancelEdit={handleCancelEdit} sendTo="/profile" pageTitle="My Profile"/>
                    {showAlert &&
                    <Alert severity={alertSeverity} sx={{width: "100%", boxSizing: "border-box", marginTop: "10px"}}>
                        {alertMessage}
                    </Alert>
                    }
                <Grid item xs={12}>
                {userData.profilePictureURL &&
                <img src={userData.profilePictureURL} className="profilePicture"/>
                }
                <Button color="primary" component="label" variant="contained" startIcon={<CloudUploadIcon />} sx={{width: "100%", border: "1px lightgrey solid", borderRadius: "4px", backgroundColor: "white", color: "#166FFF"}}>
                    Update profile picture
                    <VisuallyHiddenInput id="profilePicture" type="file" onChange={(e) => handleEdit(e)}/>
                </Button>
                </Grid>
                <Grid item xs={12}>
                <TextField sx={{width: "100%", marginTop: "15px"}} label="Username" id="username" value={userData.username} onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                <TextField sx={{width: "100%", marginTop: "15px"}} label="First Name" id="firstName" value={userData.firstName} onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                <Grid item xs={6}>
                <TextField sx={{width: "100%", marginTop: "15px"}} label="Last Name" id="lastName" value={userData.lastName} onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                </Grid>
                <Grid item xs={12}>
                <TextField disabled sx={{width: "100%", marginTop: "15px"}} label="Email" id="email" value={userData.email} onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                <Grid item xs={12}>
                <TextField sx={{width: "100%", marginTop: "15px"}} label="Location" id="location" 
                InputProps={{
                    endAdornment: <InputAdornment sx={{position: "absolute", bottom: 20, right: 10}}position="end">{totalLocationCount}/20</InputAdornment>,
                }}
                value={userData.location} onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                <Grid item xs={12}>
                <TextField sx={{width: "100%", marginTop: "15px", position: "relative"}} multiline label="Summary" id="summary" value={userData.summary} 
                    InputProps={{
                        endAdornment: <InputAdornment sx={{position: "absolute", bottom: 20, right: 10}}position="end">{totalSummaryCount}/120</InputAdornment>,
                    }}
                onChange={(e) => handleEdit
                (e)}/>
                </Grid>
                {editsMade ?
                <Button sx={{width: "100%", marginTop: "15px"}} variant="contained" onClick={() => handleUpdateUser(userData.username, userData.firstName, userData.lastName, userData.email, userData.location, userData.summary, props.userDbId, userData.profilePicturePath, userData.profilePictureURL)}>Update Profile</Button>
                :
                <Button disabled sx={{width: "100%", marginTop: "15px"}} variant="outlined">Update Profile</Button>
                }
                </>
                :
                <>
                <PageHeader sendTo="/" pageTitle="My Profile"/>
                <Grid container sx={{textAlign: "center"}} spacing={2}>
                        <Grid item xs={6}>
                            <Button sx={{width: "100%"}} variant="outlined" onClick={handleEdit}>Edit Profile</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button sx={{width: "100%"}} variant="outlined" onClick={signOutUser}>Sign Out</Button>
                        </Grid>
                    </Grid>
                <Grid container sx={{marginBottom: "10px"}} className="profileInfo">
                    <Grid xs={4} sm={2} md={3}>
                        <img src={userData.profilePictureURL} className="profilePicture" style={{margin: "20px 0px 10px 0px"}}/>
                    </Grid>
                    <Grid xs={8} sm={10} md={9} sx={{padding: "0px 10px"}}>
                        <h3 style={{marginBottom: "0px"}}>{userData.firstName} {userData.lastName}</h3>
                        <p>{userData.username}</p>
                        <p>{userData.location}</p>
                    </Grid>
                    <Box sx={{display: "block", width: "100%"}}>
                        <p>{userData.summary}</p>
                    </Box>
                </Grid>
                <Box>
                    <TabsComponent tabsList={["Favorites", "My Recipes"]} myRecipes={userRecipes} favoriteRecipes={favoriteRecipes} currentUserDbId={props.userDbId} changedFavorites={changedFavorites} loadReady={loadReady}/>
                </Box>
                </>}
                </Container>
        )
    }


export default UserProfile