import React from "react"
import { useParams } from "react-router"
import AddRecipe from "./AddRecipe"

export default function EditRecipeWrapper (props) {

    let params = useParams()

    return <AddRecipe 
        recipeId={params.recipeId}
        userProfilePictureUrl={props.userProfilePictureUrl}
        userDbId={props.userDbId}
        title={props.title}
        editingRecipe={true}/>

}