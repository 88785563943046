import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVsGL2eRDfQe7AZstI2Ah66gOeJo_a99Y",
  authDomain: "hotpatata-da973.firebaseapp.com",
  projectId: "hotpatata-da973",
  storageBucket: "hotpatata-da973.appspot.com",
  messagingSenderId: "782093190868",
  appId: "1:782093190868:web:c9d1cd1133d3ed56b95d41",
  measurementId: "G-P704E7EMYZ"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const vertexAI = getVertexAI(app);
const analytics = getAnalytics(app);

const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

export{
  app,
  storage,
  analytics,
  model
}