import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from "@mui/material/Tabs";
import RecipeCard from '../RecipeCard';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';  

export default function TabsComponent(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkAsFavorite = (recipeDbId) => {
    return props.favoriteRecipes.map(recipe => {
      return recipe.dbId
    })
    .indexOf(recipeDbId) > -1
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {props.tabsList.map((tab, index) => {
              return <Tab sx={{width: "50%"}} label={tab} />
          })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} sx={{maxWidth: "100px"}}>
        <Grid container spacing={2}>
          {props.loadReady ?
          <>
              {props.favoriteRecipes.map((recipe) => {
          let favoriteCount = 0;
          if(recipe.favoriteCount) {
            favoriteCount = recipe.favoriteCount
          }
          const isFavorite = checkAsFavorite(recipe.dbId)
          return (
          <Grid item xs={6} sm={4} md={3}>
          <RecipeCard
          key={recipe.dbId}
          recipeTitle={recipe.title}
          prepTime={recipe.prepTime}
          cookTime={recipe.cookTime}
          recipeDbId={recipe.dbId}
          userPicture={recipe.userPicture}
          recipePicture={recipe.recipePictureUrl}
          recipeTags={recipe.tags}
          userUsername={recipe.userUsername}
          recipeUserDbId={recipe.userDbId}
          currentUserDbId={props.currentUserDbId}
          type={recipe.type}
          auth={true}
          favoriteCount={favoriteCount}
          changedFavorites={props.changedFavorites}
          isFavoriteRecipe={isFavorite}
          />
          </Grid>
          )
        })}
        </>
        :
        <CircularProgress sx={{margin: "10px auto"}}/>
      }
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} sx={{maxWidth: "100px"}}>
        <Grid container spacing={2}>
        {props.myRecipes.map((recipe) => {
          let favoriteCount = 0;
          if(recipe.favoriteCount) {
            favoriteCount = recipe.favoriteCount
          }
          const isFavorite = checkAsFavorite(recipe.dbId)
          return (
          <Grid item xs={6} sm={4} md={3}>
          <RecipeCard
          key={recipe.dbId}
          recipeTitle={recipe.title}
          prepTime={recipe.prepTime}
          cookTime={recipe.cookTime}
          recipeDbId={recipe.dbId}
          userPicture={recipe.userPicture}
          recipePicture={recipe.recipePictureUrl}
          recipeTags={recipe.tags}
          userUsername={recipe.userUsername}
          recipeUserDbId={recipe.userDbId}
          currentUserDbId={props.currentUserDbId}
          type={recipe.type}
          changedFavorites={props.changedFavorites}
          auth={true}
          favoriteCount={favoriteCount}
          isFavoriteRecipe={isFavorite}
          prevLocation="userProfile"
          />
          </Grid>
          )
        })}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}